export enum OrgStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Blocked = 'Blocked',
  Pending = 'Pending',
}
export enum OrgFeatures {
  JudgeReimbursementStatement = 'JudgeReimbursementStatement',
}

export enum OrgFeatureStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum FeeType {
  Fixed = 'Fixed',
  Percentage = 'Percentage',
}

export enum FeeCriteria {
  gte = 'gte',
  lte = 'lte',
  lt = 'lt',
  gt = 'gt',
  eq = 'eq',
}

export enum InvoiceStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Overdue = 'Overdue',
  Paid = 'Paid',
  Cancelled = 'Cancelled',
  Void = 'Void',
}
