import { createContext, useContext, useEffect } from 'react';
import { ActivityLogCtx } from './activity-log';
import { ActivityLog, IParseLogUrl } from 'src/types/activity-log';
import {
  ActivityLogEventType,
  ActivityLogSubjects,
} from '@ej-lincoln/shared-resources';
import { useRouter } from 'next/router';
import { analytics } from 'src/lib/analytics';
import { User } from 'src/types/user';
import { ParsedUrlQuery } from 'querystring';
import { getLogger } from 'src/pino/log-util';
import { Crisp } from 'crisp-sdk-web';
import * as Sentry from '@sentry/browser';
import { useAuth } from 'src/hooks/use-auth';

export const EventTrackerCtx = createContext<{
  identify: (currentUser: User) => void;
  track: (event: object) => void;
  reset: () => void;
}>({
  identify: () => { },
  track: (event: object) => { },
  reset: () => { },
});

export function EventTrackerWrapper({ children }) {
  const { createLog } = useContext(ActivityLogCtx);
  const { user } = useAuth();

  const router = useRouter();

  //Pino logger
  const logger = getLogger('app');

  useEffect(() => {
    user && identify(user);
  }, [user]);

  useEffect(() => {
    const handleRouteChange = async (url: string) => {
      const urlInfo: IParseLogUrl | boolean = await processUrlForLog(
        url,
        router.query,
      );

      if (!urlInfo) {
        return false;
      }

      page({
        name: urlInfo?.pageName,
        subject: urlInfo?.subject,
        subjectRef: urlInfo?.subjectRef,
        gqlOperation: '',
        eventType: urlInfo?.eventType,
        claimNumber: urlInfo?.claimNumber,
        event: urlInfo?.event,
      });
    };

    router.isReady && handleRouteChange(router.asPath);

    /**
     * routeChangeComplete approach discarded due to misbehavior: https://github.com/vercel/next.js/issues/11639
     */

    // router.events.on('routeChangeComplete', url =>
    //   handleRouteChange(url, 'COMPLETE'),
    // );

    // return () => {
    //   router.events.off('routeChangeComplete', handleRouteChange);
    // };
  }, [router.isReady, router.asPath]);

  const identify = (currentUser?: User) => {
    //Analytics plugins identify (Mixpanel, gtm, etc)
    analytics.identify(currentUser?._id, {
      name: `${currentUser?.firstName || ''} ${currentUser?.middleName || ''} ${currentUser?.lastName || ''
        }`,
      company: currentUser?.company,
      jobTitle: currentUser?.jobTitle,
      org: currentUser?.orgs[0].org?._id,
      email: currentUser?.emails[0].emailAddress,
      $email: currentUser?.emails[0].emailAddress, // Mixpanel alias for email
      appVersion:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development',
      appEnvironment:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || 'development',
    });

    //Crisp identify
    Crisp.user.setEmail(currentUser?.emails[0]?.emailAddress);
    Crisp.user.setNickname(
      `${currentUser?.firstName} ${currentUser?.lastName}`,
    );
    Crisp.session.setData({
      userId: currentUser?._id || '',
      name: `${currentUser?.firstName || ''} ${currentUser?.middleName || ''} ${currentUser?.lastName || ''
        }`,
      company: currentUser?.company || '',
      jobTitle: currentUser?.jobTitle || '',
      org: currentUser?.orgs[0].org?._id || '',
      email: currentUser?.emails[0].emailAddress || '',
      appVersion:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development',
      appEnvironment:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || 'development',
    });

    //Sentry identify
    Sentry.setUser({
      id: currentUser?._id,
      email: currentUser?.emails[0].emailAddress,
    });
  };

  const track = (event: object) => {
    //Analytics plugins track (Mixpanel, gtm, etc)
    analytics.track(
      event?.name,
      {
        subject: event?.subject,
        subjectRef: event?.subjectRef,
        type: event?.eventType,
        appVersion:
          process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development',
        appEnvironment:
          process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || 'development',
        description: event?.event,
      },
      {
        plugins: {
          // disabling track event for mixpanel conditionally
          mixpanel: event?.dontTrack && false,
        },
      },
    );

    //Activity log track
    const activityLog: ActivityLog = {
      subject: event?.subject,
      subjectRef: event?.subjectRef,
      claimNumber: event?.claimNumber,
      gqlOperation: '',
      eventType: event?.eventType,
      event: event?.event,
    };

    createLog(activityLog);

    //Pino logging
    logger.info(`${event?.event}`);
  };

  const page = (event: object) => {
    //Analytics plugins page event (Mixpanel, gtm, etc)
    analytics.page({
      ...event,
      path: event?.name,
      pathname: router.pathname,
    });

    createLog(event);

    //Pino logging
    logger.info(`${event?.event}`, event);
  };

  const reset = () => {
    //Analytics reset
    analytics.reset();

    //Sentry reset
    Sentry.setUser(null);
  };

  async function processUrlForLog(
    url: string,
    query: ParsedUrlQuery,
  ): Promise<IParseLogUrl | boolean> {
    const UrlMap = [
      {
        urlPattern: new RegExp('^/$'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User visited homepage',
        claimNumber: '',
        pageName: 'Homepage',
      },
      {
        urlPattern: new RegExp('^/claims$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User viewed claims list',
        claimNumber: '',
        pageName: 'Claims List',
      },
      {
        urlPattern: new RegExp('^/claims/[0-9]+$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: `User viewed claim ${query.claimNumber}`,
        claimNumber: query.claimNumber,
        pageName: 'Claim Details',
      },
      {
        urlPattern: new RegExp('^/claims/[0-9]+/messages$'),
        subject: ActivityLogSubjects.Message,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: `User viewed claim ${query.claimNumber} messages list`,
        claimNumber: query.claimNumber,
        pageName: 'Claim Messages List',
      },
      {
        urlPattern: new RegExp('^/claims/[0-9]+/messages/.+$'),
        subject: ActivityLogSubjects.Message,
        subjectRef: query.messageId,
        eventType: ActivityLogEventType.View,
        event: `User viewed claim ${query.claimNumber} message`,
        claimNumber: query.claimNumber,
        pageName: 'Claim Message Thread',
      },
      {
        urlPattern: new RegExp('^/claims/initiate$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User visited Claim initiate page',
        claimNumber: '',
        pageName: 'Initiate Claim Splash',
      },
      {
        urlPattern: new RegExp('^/claims/initiate/[0-9]+$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: 'User creating new Claim',
        claimNumber: '',
        pageName: 'Initiate Claim Form',
      },
      {
        urlPattern: new RegExp('^/claims/[0-9]+/submission/claimant$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: 'User creating claimant submission',
        claimNumber: query.claimNumber,
        pageName: 'Claimant Submission 1',
      },
      {
        urlPattern: new RegExp('^/claims/[0-9]+/submission/respondent$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: 'User creating respondent submission',
        claimNumber: query.claimNumber,
        pageName: 'Respondent Submission 1',
      },
      {
        urlPattern: new RegExp('^/claims/[0-9]+/response/claimant$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: 'User creating claimant response',
        claimNumber: query.claimNumber,
        pageName: 'Claimant Submission 2',
      },
      {
        urlPattern: new RegExp('^/claims/[0-9]+/response/respondent$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: 'User creating claimant respondent',
        claimNumber: query.claimNumber,
        pageName: 'Respondent Submission 2',
      },
      {
        urlPattern: new RegExp('^/claims/[0-9]+/decision$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: 'User viewing claim decision',
        claimNumber: query.claimNumber,
        pageName: 'Claim Decision',
      },
      {
        urlPattern: new RegExp('^/claims/[0-9]+/conflicts$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: 'Judge clearing conflicts',
        claimNumber: query.claimNumber,
        pageName: 'Clear Conflicts',
      },
      {
        urlPattern: new RegExp('^/claims/start$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'Claimant starting the claim',
        claimNumber: query.claimNumber,
        pageName: 'Start Claim Splash',
      },
      {
        urlPattern: new RegExp('^/claims/start/[0-9]+$'),
        subject: ActivityLogSubjects.Claim,
        subjectRef: query.claimNumber,
        eventType: ActivityLogEventType.View,
        event: 'Claimant creating new claim',
        claimNumber: query.claimNumber,
        pageName: 'Start Claim Form',
      },
      {
        urlPattern: new RegExp('^/profile$'),
        subject: ActivityLogSubjects.User,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User viewed his profile',
        claimNumber: '',
        pageName: 'Profile',
      },
      {
        urlPattern: new RegExp('^/profile/edit$'),
        subject: ActivityLogSubjects.User,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User editing his profile',
        claimNumber: '',
        pageName: 'Edit Profile',
      },
      {
        urlPattern: new RegExp('^/admin/orgs$'),
        subject: ActivityLogSubjects.Organization,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'Admin viewed orgs list',
        claimNumber: '',
        pageName: 'Admin Orgs List',
      },
      {
        urlPattern: new RegExp('^/admin/orgs/create$'),
        subject: ActivityLogSubjects.Organization,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'Admin creating new org',
        claimNumber: '',
        pageName: 'Admin Create Org',
      },
      {
        urlPattern: new RegExp('^/admin/orgs/\\w+$'),
        subject: ActivityLogSubjects.Organization,
        subjectRef: query.orgId,
        eventType: ActivityLogEventType.View,
        event: 'Admin viewing org',
        claimNumber: '',
        pageName: 'Admin Org Details',
      },
      {
        urlPattern: new RegExp('^/admin/orgs/\\w+/edit$'),
        subject: ActivityLogSubjects.Organization,
        subjectRef: query.orgId,
        eventType: ActivityLogEventType.View,
        event: 'Admin editing org',
        claimNumber: '',
        pageName: 'Admin Edit Org',
      },
      // Users
      {
        urlPattern: new RegExp('^/admin/users$'),
        subject: ActivityLogSubjects.User,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'Admin viewed users list',
        claimNumber: '',
        pageName: 'Admin Users List',
      },
      {
        urlPattern: new RegExp('^/admin/users/create$'),
        subject: ActivityLogSubjects.User,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'Admin creating new user',
        claimNumber: '',
        pageName: 'Admin Create User',
      },
      {
        urlPattern: new RegExp('^/admin/users/\\w+$'),
        subject: ActivityLogSubjects.User,
        subjectRef: query.userId,
        eventType: ActivityLogEventType.View,
        event: 'Admin viewing user',
        claimNumber: '',
        pageName: 'Admin User Details',
      },
      {
        urlPattern: new RegExp('^/admin/users/\\w+/edit$'),
        subject: ActivityLogSubjects.User,
        subjectRef: query.userId,
        eventType: ActivityLogEventType.View,
        event: 'Admin editing user',
        claimNumber: '',
        pageName: 'Admin Edit User',
      },
      {
        urlPattern: new RegExp('^/401$'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User viewed page 401',
        claimNumber: '',
        pageName: '401 Page',
      },
      {
        urlPattern: new RegExp('^/500$'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User viewed page 500',
        claimNumber: '',
        pageName: '500 Page',
      },
      {
        urlPattern: new RegExp('^/404$'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User viewed page 404',
        claimNumber: '',
        pageName: '404 Page',
      },
      {
        urlPattern: new RegExp('^/authentication/login(.*)'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User visited login page',
        claimNumber: '',
        pageName: 'Login',
      },
      {
        urlPattern: new RegExp('^/authentication/logout(.*)'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User visited logout page',
        claimNumber: '',
        pageName: 'Logout',
      },
      {
        urlPattern: new RegExp('^/authentication/register'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User visited register page',
        claimNumber: '',
        pageName: 'Register',
      },
      {
        urlPattern: new RegExp('^/authentication/password-recovery'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User visited password recovery page',
        claimNumber: '',
        pageName: 'Password Recovery',
      },
      {
        urlPattern: new RegExp('^/authentication/password-reset'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User visited password reset page',
        claimNumber: '',
        pageName: 'Password Reset',
      },
      {
        urlPattern: new RegExp('^/authentication/verify-code'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User visited verify code page',
        claimNumber: '',
        pageName: 'Verify Code',
      },
      {
        urlPattern: new RegExp('^/join'),
        subject: ActivityLogSubjects.App,
        subjectRef: '',
        eventType: ActivityLogEventType.View,
        event: 'User visited join page',
        claimNumber: '',
        pageName: 'Join',
      },
    ];

    const route = UrlMap.find(({ urlPattern }) => urlPattern.test(url));

    if (!route) {
      console.warn(`No URL Mapping for activity logs found for url ${url}`);
      return false;
    }

    const urlInfo: IParseLogUrl = {
      pageName: route.pageName,
      subject: route.subject,
      subjectRef: route.subjectRef,
      claimNumber: route.claimNumber,
      eventType: route.eventType,
      event: `${route.event} URL ${url}`,
    };

    return urlInfo;
  }

  return (
    <EventTrackerCtx.Provider value={{ identify, track, reset }}>
      {children}
    </EventTrackerCtx.Provider>
  );
}

export function useEventTrackerContext() {
  return useContext(EventTrackerCtx);
}
