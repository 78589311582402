export enum ReminderSendTo {
  Respondent = 'Respondent',
  Claimant = 'Claimant',
  Judge = 'Judge',
  SuperAdmin = 'SuperAdmin',
}

export enum ReminderChannel {
  Email = 'Email',
  SMS = 'SMS',
}

export enum ReminderType {
  ClaimActionReminderEmail = 'ClaimActionReminderEmail',
}

export enum ReminderStatus {
  Pending = 'Pending',
  Sent = 'Sent',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Skipped = 'Skipped',
}
